'use strict';

const accordionModule = {
    init: () => {
        const aAccordions = document.querySelectorAll('[data-module-accordion]');
        const fInitAccordion = oAccordion => {
            if (!oAccordion.classList.contains('activated')) {
                oAccordion.classList.add('activated');
                const aTriggers = oAccordion.querySelectorAll('[data-module-accordion-open]');
                const bOpenOne = oAccordion.getAttribute('data-accordion-openOne') === 'true';

                for (let b = 0; b < aTriggers.length; b += 1) {
                    const oParent = aTriggers[b].parentNode;
                    const oCollapse = oParent.querySelector('[data-module-accordion-collapse]');
                    const oCollapseHeight = oCollapse.offsetHeight;
                    const oChevron = aTriggers[b].querySelector('.icon-chevron');

                    oCollapse.style.height = '0px';
                    oCollapse.style.position = 'relative';
                    oCollapse.style.opacity = 1;
                    oCollapse.style.visibility = 'visible';

                    if (oParent.getAttribute('data-accordion-active')) {
                        oCollapse.style.height = `${oCollapseHeight}px`;
                        if (oChevron) {
                            //Using Attribute here, IE11 Doenst support classList on SVG's
                            oChevron.removeAttribute('class', 'icon-chevron icon-chevron--down');
                            oChevron.setAttribute('class', 'icon-chevron icon-chevron--up');
                        }
                    }

                    aTriggers[b].addEventListener('click', () => {
                        if (oParent.getAttribute('data-accordion-active')) {
                            oCollapse.style.height = '0px';
                            oParent.removeAttribute('data-accordion-active');

                            if (oChevron) {
                                oChevron.removeAttribute('class', 'icon-chevron icon-chevron--up');
                                oChevron.setAttribute('class', 'icon-chevron icon-chevron--down');
                            }
                        } else {
                            if (bOpenOne) {
                                const oCurrent = oAccordion.querySelector('[data-accordion-active="true"]');

                                if (oCurrent) {
                                    const oCurrentCollapse = oCurrent.querySelector('[data-module-accordion-collapse]');

                                    oCurrentCollapse.style.height = '0px';
                                    oCurrent.removeAttribute('data-accordion-active');
                                }
                            }

                            oCollapse.style.height = `${oCollapseHeight}px`;
                            oParent.setAttribute('data-accordion-active', true);

                            if (oChevron) {
                                oChevron.removeAttribute('class', 'icon-chevron icon-chevron--down');
                                oChevron.setAttribute('class', 'icon-chevron icon-chevron--up');
                            }
                        }
                    });
                }
            }
        };

        for (let a = 0; a < aAccordions.length; a += 1) {
            fInitAccordion(aAccordions[a]);
        }
    }
}

export default accordionModule;