export default {
    getNewsItems(queryObj) {
        return fetch('/umbraco/surface/newsoverview/getnewsitems/', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(queryObj)
        }).then((resp) => resp.text()).then((data) => {
            return data;
        });
    },
}
