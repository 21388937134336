import Flickity from 'flickity';

function contentSlider() {
  const sliders = document.querySelectorAll('.js-slider');
  let screenWidth = document.body.getBoundingClientRect().width;

  function isInViewport(elem) {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.left >= 0 &&
      bounding.right <= window.innerWidth
    );
  }

  const sliderNextPrev = (control, slider) => {
    slider.stopPlayer();
    if (control.classList.contains('next')) {
      slider.next(false, false);
    } else {
      slider.previous(false, false);
    }
    if (slider.options.autoPlay) {
      slider.playPlayer();
    }
  };

  const handleSliderControlState = (index, controls, numberOfSliders, slider) => {
    for (let i = 0; i < controls.length; i += 1) {
      const control = controls[i];
      const lastSlide = slider.querySelector('.js-slider-item-last');

      // Check if last image is complety in viewport
      if (lastSlide && isInViewport(lastSlide)) {
        if (control.classList.contains('next')) {
          control.setAttribute('disabled', '');
        } else {
          control.removeAttribute('disabled', '');
        }
      } else {
        if (index === 0) {
          if (control.classList.contains('prev')) {
            control.setAttribute('disabled', '');
          } else if (control.classList.contains('next')) {
            control.removeAttribute('disabled');
          }
        } else if (index === numberOfSliders - 1) {
          if (control.classList.contains('next')) {
            control.setAttribute('disabled', '');
          } else {
            control.removeAttribute('disabled', '');
          }
        } else if (control.getAttribute('disabled') !== null) {
          control.removeAttribute('disabled');
        }
      }
    }
  };

  const bindSliderControlEvents = (controls, flickitySlider, numberOfSliders, slider) => {
    for (let i = 0; i < controls.length; i += 1) {
      controls[i].addEventListener('click', () => sliderNextPrev(controls[i], flickitySlider));
    }

    flickitySlider.on('change', (index) => {
      setTimeout(() => {
        handleSliderControlState(index, controls, numberOfSliders, slider);
      }, 250);
    });

    flickitySlider.on('settle', (index) => {
      handleSliderControlState(index, controls, numberOfSliders, slider);
    });
  };

  const initSliderNavigation = (slider, flickitySlider) => {
    const sliderContainer = slider.closest('.js-slider-container');
    const sliderControls = sliderContainer.querySelectorAll('.js-slider-control');
    const numberOfSliders = flickitySlider.slides.length;
    if (sliderControls.length) {
      bindSliderControlEvents(sliderControls, flickitySlider, numberOfSliders, slider);
    }
  };

  const animateImageTimeline = (index) => {
    const allSlides = document.querySelectorAll('[data-slide-index]');

    allSlides.forEach(slide => {
      const imageOne = slide.querySelector('[data-timeline-img-front]');
      const imageTwo = slide.querySelector('[data-timeline-img-back]');

      if (imageTwo) imageTwo.classList.remove('is-visible');
      if (imageOne) imageOne.classList.remove('is-visible');

      if (parseInt(slide.dataset.slideIndex, 10) === index) {
        if (imageTwo) {
          imageTwo.classList.add('is-visible');
        }

        setTimeout(function () {
          if (imageOne) {
            imageOne.classList.add('is-visible');
          }
        }, 200);
      }
    })
  }

  const sliderReadyCallback = (slider, flickitySlider) => {
    if (slider.getAttribute('data-slider-customnav') === 'true') {
      initSliderNavigation(slider, flickitySlider);
    }

    if (slider.closest('.js-slider-container')) {
      const oSliderContainer = slider.closest('.js-slider-container');
      oSliderContainer.classList.add('ready');
    }

    if (slider.classList.contains('slider--options')) {
      initOptionSlide(slider, flickitySlider);
    }

    if (slider.dataset.sliderTimeline !== undefined) {
      flickitySlider.slides.forEach(slide => {
        const slideEl = slide.cells[0].element;
        const itemHeight = slideEl.querySelector('[data-timeline-img]');
        const imgWrapper = slideEl.querySelector('[data-timeline-img-wrapper]');

        if (itemHeight && itemHeight.offsetHeight > slider.offsetHeight) {
          if (screenWidth < 992) {
            slider.style.height = itemHeight.offsetHeight + 80 + 'px';
          } else {
            slider.style.height = itemHeight.offsetHeight + 60 + 'px';
          }
        }

        if (imgWrapper && itemHeight) {
          imgWrapper.style.height = itemHeight.offsetHeight + 'px';
        }

      })

      flickitySlider.on('change', function (index) {
        animateImageTimeline(index);
      });
    }

    const sameHeightItems = slider.querySelectorAll('[data-slider-height]');
    sameHeightItems.forEach(item => {
      if (screenWidth < 992) {
        item.style.height = '100%';
      } else {
        item.style.height = 'auto';
      }
    })

    const sameHeightItemsAll = document.querySelectorAll('[data-slider-all-height]');
    sameHeightItemsAll.forEach(item => {
      item.style.height = '100%';
    })
  };

  function handleTimer(slider, flickitySlider) {
    if (slider.getAttribute('data-slider-timer') !== '') {
      const sliderTimer = slider.parentNode.querySelector('.js-slider-timer');
      const sliderTimerProgress = sliderTimer.querySelector('.js-slider-progress');
      const offset = sliderTimer.offsetWidth;

      const timing = parseInt(slider.getAttribute('data-slider-timer'), 10);
      let progress = 0;
      const step = ((offset - 6) / timing) * 10;

      const interval = setInterval(function () {
        if (progress >= (offset - 6)) {
          flickitySlider.next(true);
          sliderTimerProgress.style.width = '0px';
          progress = 0;
        } else {
          progress = progress + step;
          sliderTimerProgress.style.width = progress + 'px';
        }
      }, 10);

      flickitySlider.on('change', () => {
        sliderTimerProgress.style.width = '0px';
        progress = 0;
      });
    }
  }

  function initSlider(slider, destroy) {
    const flickitySlider = new Flickity(slider, {
      contain: true,
      autoPlay: slider.getAttribute('data-autoplay') ? parseInt(slider.getAttribute('data-autoplay'), 10) : false,
      pauseAutoPlayOnHover: slider.getAttribute('data-pauseautoplay') === 'true' ? true : false,
      cellAlign: 'left',
      wrapAround: slider.getAttribute('data-slider-wrap') === 'true',
      prevNextButtons: slider.getAttribute('data-slider-nav') === 'true',
      pageDots: slider.getAttribute('data-slider-dots') === 'true',
      groupCells: slider.getAttribute('data-groupcells') ? slider.getAttribute('data-groupcells') : false,
      fade: slider.getAttribute('data-fade') === 'true' ? true : false,
      on: {
        ready: () => {
          setTimeout(() => {
            sliderReadyCallback(slider, flickitySlider);
          }, 0);
        },
        dragEnd: () => {
          if (flickitySlider.options.autoPlay) {
            flickitySlider.playPlayer();
          }
        }
      }
    });

    if (destroy) {
      flickitySlider.destroy();
      slider.classList.remove('flickity-enabled');
    }

    if (slider.getAttribute('data-slider-timer')) {
      handleTimer(slider, flickitySlider);
    }

    if (slider.dataset.sliderTimeline !== undefined) {
      animateImageTimeline(0);
    }

    const cellsButtonGroup = document.querySelector('.button-group');
    if (cellsButtonGroup !== null) {
      const cellsButtons = cellsButtonGroup.querySelectorAll('.button-group__button');

      flickitySlider.on('select', function () {
        const previousSelectedButton = cellsButtonGroup.querySelector('.is-selected');
        const selectedButton = cellsButtonGroup.children[flickitySlider.selectedIndex];
        previousSelectedButton.classList.remove('is-selected');
        selectedButton.classList.add('is-selected');
      });

      for (let i = 0; i < cellsButtons.length; i += 1) {
        cellsButtons[i].addEventListener('click', () => {
          flickitySlider.selectCell(i);
        });
      }
    }
  }

  window.addEventListener('resize', function () {
    screenWidth = document.body.getBoundingClientRect().width;
    const sameHeightItems = document.querySelectorAll('[data-slider-height]');

    sliders.forEach(slider => {
      let mobileBreakpoint = slider.dataset.sliderBreakpoint;

      if (!mobileBreakpoint) {
        mobileBreakpoint = 992;
      }

      if (slider.dataset.sliderMobile === 'true' && screenWidth > mobileBreakpoint) {
        if (slider.classList.contains('flickity-enabled')) {
          initSlider(slider, true);
        }
      } else {
        if (!slider.classList.contains('flickity-enabled')) {
          initSlider(slider, false);
        }
      }

      if (screenWidth > mobileBreakpoint) {
        sameHeightItems.forEach(item => {
          item.style.height = 'auto';
        });
      } else {
        sameHeightItems.forEach(item => {
          item.style.height = '100%';
        });
      }

      if (slider.dataset.sliderTimeline !== undefined) {
        initSlider(slider, true);
        initSlider(slider, false);
      }
    });
  });


  sliders.forEach(slider => {
    let mobileBreakpoint = slider.dataset.sliderBreakpoint;

    if (!mobileBreakpoint) {
      mobileBreakpoint = 992;
    }

    if (slider.dataset.sliderMobile === 'true' && screenWidth <= mobileBreakpoint) {
      initSlider(slider, false);
    } else if (slider.dataset.sliderMobile === undefined) {
      initSlider(slider, false);
    }
  });
}

export default contentSlider;
