const navModule = () => {
    const navDropdowns = document.querySelectorAll('[data-module-nav-dropdown]');
    const navToggle = document.querySelector('[data-module-nav-toggle]');
    const offscreenNav = document.querySelector('[data-module-nav-offscreen]');

    function openDropdown(toggle, dropdown) {
        dropdown.classList.add('is-open');
        if (toggle) toggle.setAttribute('aria-expanded', 'true');
    }

    function closeDropdown(toggle, dropdown) {
        dropdown.classList.remove('is-open');
        if (toggle) toggle.setAttribute('aria-expanded', 'false');
    }

    function toggleNavigation(e) {
        // Close all submenus when closing navigation
        if (offscreenNav.classList.contains('is-open')) {
            navDropdowns.forEach((item) => {
                const dropdown = item.querySelector('.navigation__dropdown__menu');
                const toggle = item.querySelector('.navigation__dropdown__toggle');
                closeDropdown(toggle, dropdown);
            });
        }

        offscreenNav.classList.contains('is-open') ? offscreenNav.classList.remove('is-open') : offscreenNav.classList.add('is-open');
        document.body.classList.contains('no-scroll') ? document.body.classList.remove('no-scroll') : document.body.classList.add('no-scroll');

        navToggle.classList.contains('is-active') ? navToggle.classList.remove('is-active') : navToggle.classList.add('is-active');
        navToggle.querySelector('span').innerText = offscreenNav.classList.contains('is-open') ? 'Sluiten' : 'Menu';
    }

    function toggleDropdown(toggle, dropdown) {
        if (dropdown.classList.contains('is-open')) {
            closeDropdown(toggle, dropdown);
        } else {
            openDropdown(toggle, dropdown);
        }
    }

    function initNavigation() {
        if(navToggle) {
            navToggle.addEventListener('click', toggleNavigation);
        }


        Array.prototype.forEach.call(navDropdowns, (el, i) => {
            const dropdown = el.querySelector('.navigation__dropdown__menu');
            const toggles = el.querySelectorAll('.navigation__dropdown__toggle');

            // Check if parent nav isnt offscreen navigation
            if (el.closest('nav').getAttribute('data-module-nav-offscreen') === null) {
                el.addEventListener('mouseover', () => {
                    openDropdown(toggles[0], dropdown);
                });

                el.addEventListener('mouseout', () => {
                    closeDropdown(toggles[0], dropdown);
                });
            }

            toggles.forEach((toggle) => {
                toggle.addEventListener('touchend', (event) => {
                    toggleDropdown(toggle, dropdown);
                    event.preventDefault();
                    return false;
                });

                toggle.addEventListener('click', (event) => {
                    toggleDropdown(toggle, dropdown);
                    return false;
                });
            });
        });
    }

    initNavigation();
}

export default navModule;