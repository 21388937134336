'use strict';

function fStickyHeader () {
    const oHeader = document.querySelector('[data-sticky-header]');

    if (!oHeader) return;

    const oHeaderOffset = oHeader.offsetTop;

    function fSwitchState () {
        const iScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

        if (iScrollPosition > oHeaderOffset) {
            oHeader.classList.add('is-sticky');
        } else {
            oHeader.classList.remove('is-sticky');
        }
    }

    fSwitchState();
    document.addEventListener('scroll', fSwitchState);
}

export default fStickyHeader;
